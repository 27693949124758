<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <p>{{isEdit?'Edit':'Add'}} Make</p>
      </div>
      <div
        class="dialog-body"
      >

        <div
          class="transparent">
          <el-form
            ref="form"
            :model="formData"
            :rules="rules">
            <div class="form-item">
              <span>Make</span>
              <el-form-item
                prop="name"
                :rules="rules.input">
                <v-input
                  v-model="formData.name"
                  :disabled="isEdit"></v-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item
                prop="status"
                :rules="rules.select">
                <v-radio-group
                  v-model="formData.status"
                  :list="makeStatusOptions"></v-radio-group>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="handleCancel">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerSave">Confirm
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {addMakeList,editMakeList} from '@api/setting'
export default {
  name: 'createReferrerDialog',
  mixins: [MixinOptions,MixinRules],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    value:{
      type:Boolean,
      default:false
    },
    classId:{
      type:[Number,String],
      default:1003
    },
    isEdit:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      dialog:false,
      formData:{
        name:'',
        status:''
      }

    }
  },
  watch:{
    form:{
      immediate:true,
      handler(val){
        if(val)
        {
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
            }
          })
        }
      }
    },
    value:{
      immediate:true,
      handler(val){
        this.dialog = val
      }
    }
  },
  methods: {
    ...mapActions('common',['updateGlobalOptions']),
    handleCancel(){
      this.dialog = false
      this.$refs.form.resetFields()
      this.$emit('input',this.dialog)
    },
    handlerSave(){
      this.$refs['form'].validate(valid => {
        if(valid) {
          if(this.isEdit){
            this.network().editMakeList()
          }else {
            this.network().addMakeList()
          }
        } else {
        }
      })
    },
    network() {
      return {
        addMakeList: async () => {
          const { data } = await addMakeList({
            class_id:this.classId,
            ...this.formData
          })
          this.$refs.form.resetFields()
          this.updateGlobalOptions()
          this.$emit('input',false)
          this.$emit('refresh')
        },
        editMakeList: async () => {
          let {status} = this.formData
          let {id} = this.form
          const { data } = await editMakeList({
            class_id:this.classId,
            id,
            status
          })
          this.$refs.form.resetFields()
          this.updateGlobalOptions()
          this.$emit('input',false)
          this.$emit('refresh')

        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #616266;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      //margin: 0 -15px;
      //padding: 30px 30px 0 30px;
      //   border-bottom: 1px solid #dddfe7;
      p {
        color: #000;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #2D3748;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          color: #2D3748;
        }
      }
    }

    .transparent{
      margin-bottom: 20px;
    }
  }
}
</style>
