import axios from '@/jslib/axios'

export const getMakeList = params => {
  return axios({
    method: 'get',
    url: '/admin/car/make',
    params
  })
}
export const addMakeList = data => {
  return axios({
    method: 'post',
    url: '/admin/car/make',
    data
  })
}
export const editMakeList = data => {
  return axios({
    method: 'patch',
    url: '/admin/car/make',
    data
  })
}
